.header .logobar {
  background: #f3f3f3;
  border-bottom: 1px solid #e7e7e7;
}
.header .logobar .logobar-i {
  display: flex;
  align-items: center;
  position: relative;
  height: 75px;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i {
    height: 50px;
    margin-bottom: 41px;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .toggle-nav-w {
    display: block;
  }
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav {
  position: relative;
  cursor: pointer;
  height: 34px;
  width: 34px;
  border: 1px solid #e1e1e1;
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav {
  position: absolute;
  left: 6px;
  top: 15px;
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav span,
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav span:before,
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 20px;
  background: #7a7a7a;
  position: absolute;
  display: block;
  content: '';
  transition: all 300ms ease-in-out;
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav span:before {
  top: -7px;
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav span:after {
  bottom: -7px;
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav.active span {
  background-color: transparent;
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav.active span:before,
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav.active span:after {
  top: 0;
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav.active span:before {
  transform: rotate(45deg);
}
.header .logobar .logobar-i .toggle-nav-w .toggle-nav .icon-nav.active span:after {
  transform: rotate(-45deg);
}
.header .logobar .logobar-i .logo-search {
  display: flex;
  align-items: center;
  flex: 1;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .logo-search {
    position: static;
  }
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .logo-search .link-logo {
    position: absolute;
    left: 50%;
    margin-left: -60px;
  }
}
.header .logobar .logobar-i .logo-search .link-logo .img-logo {
  width: 170px;
  height: auto;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .logo-search .link-logo .img-logo {
    width: 120px;
  }
}
.header .logobar .logobar-i .logo-search .form-search {
  position: relative;
  margin: 0 25px;
  flex: 1;
}
.header .logobar .logobar-i .logo-search .form-search i.fas {
  position: absolute;
  margin-top: 11px;
  margin-left: 12px;
}
.header .logobar .logobar-i .logo-search .form-search .input-search {
  width: 100%;
  height: 38px;
  padding: 10px 20px 10px 36px;
  border: 2px solid #545454;
  border-radius: 4px;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .logo-search .form-search .input-search {
    border: 0;
    height: 32px;
  }
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .logo-search .form-search {
    padding: 7px 20px;
    border-radius: 4px 4px 0 0;
    background: #003f8f;
    position: absolute;
    bottom: -42px;
    left: 0;
    margin-left: 0;
    width: 100%;
  }
  .header .logobar .logobar-i .logo-search .form-search .icon-search {
    margin-top: 15px;
  }
}
.header .logobar .logobar-i .login-w {
  margin-left: auto;
  margin-right: 6px;
}
.header .logobar .logobar-i .login-w .login {
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .login-w .login .login-i>.btn {
    padding: 0;
  }
}
.header .logobar .logobar-i .login-w .login .login-i {
  user-select: none;
  cursor: pointer;
}
.header .logobar .logobar-i .login-w .login .login-i .btn {
  display: flex;
  justify-content: center;
}
.header .logobar .logobar-i .login-w .login .login-i .login-actions {
  z-index: 999;
  display: none;
  padding: 4px 4px 0;
  background: #8ba3cf;
  border-radius: 4px;
  position: absolute;
  min-width: 100%;
  flex-direction: column;
}
.header .logobar .logobar-i .login-w .login .login-i .login-actions .btn {
  margin-bottom: 5px;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .login-w .login .login-i .login-actions {
    right: 0;
    padding: 5px 10px 0;
  }
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .login-w .login .login-i .icon-w {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    top: -1px;
  }
}
.header .logobar .logobar-i .login-w .login .login-i .login-txt {
  padding-right: 3px;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .login-w .login .login-i .login-txt {
    display: none;
  }
}
.header .logobar .logobar-i .cart-w {
  margin-left: auto;
}
.header .logobar .logobar-i .cart-w .cart {
  position: relative;
}
.header .logobar .logobar-i .cart-w .cart .cart-i {
  user-select: none;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .cart-w .cart .cart-i .btn {
    padding: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .cart-w .cart .cart-i .icon-w {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    top: -1px;
  }
}
.header .logobar .logobar-i .cart-w .cart .cart-i .cart-txt {
  padding-right: 3px;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .cart-w .cart .cart-i .cart-txt {
    display: none;
  }
}
.header .logobar .logobar-i .cart-w .content-cart {
  position: absolute;
  display: none;
  z-index: 999;
  padding: 15px 15px;
  top: 52px;
  background: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
}
.header .logobar .logobar-i .cart-w .content-cart:before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-bottom: 0.9rem solid #fff;
  top: -10px;
}
.header .logobar .logobar-i .cart-w .cart .content-cart.visible {
  display: flex;
}
.header .logobar .logobar-i .cart-w .cart .content-cart {
  min-height: 243px;
  width: 322px;
  right: 0;
}
.header .logobar .logobar-i .cart-w .cart .content-cart:before {
  right: 105px;
}
@media only screen and (max-width: 1024px) {
  .header .logobar .logobar-i .cart-w .cart .content-cart:before {
    right: 12px;
  }
}
.header .logobar .logobar-i .cart-w .cart .content-cart .info-cart {
  color: #003f8f;
  font-size: 18px;
  padding: 0 30px;
}
.header .navbar .navbar-i {
  width: 100%;
  margin: 0;
  padding: 0;
}
.header .navbar .navbar-i a {
  text-decoration: none;
}
.header .navbar .navbar-i ul,
.header .navbar .navbar-i li {
  list-style: none;
}
.header .navbar .navbar-i .dropmenu.mobile {
  display: none;
}
.header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w {
  position: relative;
}
.header .navbar .navbar-i .dropmenu>a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  background: #003f8f;
  font-weight: bold;
  color: #fff;
  width: 100%;
  padding: 6px 12px;
}
.header .navbar .navbar-i .dropmenu>a span.caret {
  margin-left: 5px;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-item {
  padding: 0;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-item .dropmenu-child a {
  color: #003f8f;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-item .dropmenu-child .dropdown-toggle,
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-item .dropmenu-child .dropmenu-child a {
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-first>.dropmenu-child>a:hover {
  background: #daeaff;
  color: #003f8f;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-second>.dropmenu-child>a:hover {
  background: #daeaff;
  color: #003f8f;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-first>.dropmenu-child {
  border-bottom: 1px dotted #003f8f;
  background: #fff;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-first .dropmenu-child {
  border-width: 0 1px 1px 1px;
  border-style: solid solid dotted solid;
  border-color: #003f8f;
  background: #fff;
  justify-content: space-between;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-first .dropmenu-child .link-first {
  flex: 1;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-first .dropmenu-child .link-open-second {
  width: 40px;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-first .dropmenu-child:first-child {
  border-top-width: 1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.header .navbar .navbar-i .dropmenu .dropmenu-item-w .dropmenu-first .dropmenu-child:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.header .navbar {
  margin-top: 25px;
}
.header .navbar .navbar-i .dropmenu.desktop {
  position: relative;
  lost-column: 1/6;
}
.header .navbar .navbar-i .dropmenu.desktop .dropmenu-item-w {
  display: none;
  position: absolute;
  margin-top: 1px;
  width: 100%;
}
.header .navbar .navbar-i .dropmenu.desktop .dropmenu-item-w .dropmenu-item {
  position: relative;
  z-index: 9999;
  margin-top: 0;
  width: 100%;
}
.header .navbar .navbar-i .dropmenu.desktop .dropmenu-item-w .dropmenu-first .dropmenu-child {
  display: flex;
  position: relative;
  text-align: left;
}
.header .navbar .navbar-i .dropmenu.desktop .dropmenu-item-w .dropmenu-first .dropmenu-child a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: normal;
  font-size: 13px;
  white-space: normal !important;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 10px;
}
.header .navbar .navbar-i .dropmenu.desktop .dropmenu-item-w .dropmenu-first .dropmenu-child a span {
  margin-left: 4px;
}
.header .navbar .navbar-i .dropmenu.desktop .dropmenu-item-w .dropmenu-first .dropmenu-second {
  display: none;
  position: absolute;
  top: -1px;
}
.header .navbar .navbar-i .dropmenu.desktop .dropmenu-item-w .dropmenu-first .dropmenu-second a {
  width: 100%;
  justify-content: flex-start;
}
@media only screen and (max-width: 1024px) {
  .header .navbar {
    display: none;
    height: calc(100vh - 6.15rem);
    overflow-y: scroll;
    background: #f3f3f3;
  }
  .header .navbar .navbar-i {
    padding-top: 20px;
  }
  .header .navbar .navbar-i .dropmenu.desktop {
    display: none;
  }
  .header .navbar .navbar-i .dropmenu.mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    padding: 6px 10px;
    cursor: pointer;
  }
  .header .navbar .navbar-i .dropmenu.mobile a {
    margin-bottom: 1px;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w {
    display: none;
    width: 100%;
    margin-top: -1px;
    margin-bottom: 3px;
    border: 1px solid #003f8f;
    border-radius: 4px;
    overflow: hidden;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w .dropmenu-first {
    border-top: 1px solid #003f8f;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w .dropmenu-first .dropmenu-child {
    background-color: #daeaff;
    border: none;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w .dropmenu-first .dropmenu-child .dropmenu-link-w {
    min-height: 41px;
    display: flex;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w .dropmenu-first .dropmenu-child .dropmenu-link-w .link-first {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 15px;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w .dropmenu-first .dropmenu-child .dropmenu-link-w .dropdown-toggle {
    background: #daeaff;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w .dropmenu-first .dropmenu-child .dropmenu-second {
    display: none;
    border-top: 1px dotted #003f8f;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w .dropmenu-first .dropmenu-child .dropmenu-second .dropmenu-child {
    background: #fff;
  }
  .header .navbar .navbar-i .dropmenu.mobile .dropmenu-item-w .dropmenu-first .dropmenu-child .dropmenu-second .dropmenu-child a {
    justify-content: flex-start;
    padding: 0 15px;
  }
  .header .navbar .navbar-i .dropmenu.mobile .social-links {
    margin-top: 20px;
  }
  .header .navbar .navbar-i .dropmenu.mobile .social-links .social-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin: 0 5px;
  }
  .header .navbar .navbar-i .dropmenu.mobile .social-links .social-link i.fab {
    color: #003f8f;
    font-size: 28px;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
