@import "../global/___variables"

.header
    vendors = official
    @keyframes pulse {
      0% {opacity: 1;}
      50% {opacity: .3;}
      100% {opacity: 1;}
    }
    .logobar
        background cinza
        border-bottom 1px solid cinza - 5%
        .logobar-i
            display flex
            align-items: center
            position relative
            height 75px
            +below(1024px)
                height 50px
                margin-bottom 41px
                padding-bottom 0
            .toggle-nav-w
                +below(1024px)
                    display block
                .toggle-nav
                    position relative
                    cursor pointer
                    height 34px
                    width 34px
                    border 1px solid #e1e1e1
                    .icon-nav
                        position absolute
                        left 6px
                        top 15px
                        span,
                        span:before,
                        span:after
                            cursor pointer
                            border-radius 1px
                            height 2px
                            width 20px
                            background #636363 + 15%
                            position absolute
                            display block
                            content ''
                            transition all 300ms ease-in-out
                        span:before
                            top -7px
                        span:after
                            bottom -7px
                        &.active span
                            background-color transparent
                            &:before,
                            &:after
                                top 0
                            &:before
                                transform rotate(45deg)
                            &:after
                                transform rotate(-45deg)
            .logo-search
                display flex
                align-items center
                flex 1
                +below(1024px)
                    position: static
                .link-logo
                    +below(1024px)
                        position absolute
                        left 50%
                        margin-left -60px
                    .img-logo
                        width 170px
                        height: auto
                        +below(1024px)
                            width 120px
                .form-search
                    position relative
                    margin 0 25px
                    flex 1
                    i.fas
                        position absolute
                        margin-top 11px
                        margin-left 12px
                    .input-search
                        width 100%
                        height 38px
                        padding: 10px 20px 10px 36px
                        border: 2px solid #545454
                        border-radius: radius
                        +below(1024px)
                            border 0
                            height 32px
                    +below(1024px)
                        padding: 7px 20px
                        border-radius: radius radius 0 0
                        background colorp
                        position absolute
                        bottom: -42px
                        left 0
                        margin-left 0
                        width 100%
                        .icon-search
                            margin-top 15px
            .login-w
                margin-left auto
                margin-right 6px
                .login
                    position relative
                    .login-i>.btn
                        +below(1024px)
                            padding 0
                    .login-i
                        user-select none
                        cursor pointer
                        .btn
                            display flex
                            justify-content center
                        .login-actions
                            z-index 999
                            display none
                            padding 4px 4px 0
                            background colors
                            border-radius radius
                            position absolute
                            min-width 100%
                            flex-direction: column
                            .btn
                                margin-bottom 5px
                            +below(1024px)
                                right 0
                                padding 5px 10px 0
                        .icon-w
                            +below(1024px)
                                display flex
                                align-items: center
                                justify-content center
                                width 34px
                                height 34px
                                border-radius: radius
                                border 1px solid #e1e1e1
                                top -1px
                        .login-txt
                            padding-right 3px
                            +below(1024px)
                                display none
            .cart-w
                margin-left auto
                .cart
                    position relative
                    .cart-i
                        user-select none
                        cursor pointer
                        +below(1024px)
                            .btn
                                padding 0
                        .icon-w
                            +below(1024px)
                                display flex
                                align-items: center
                                justify-content center
                                width 34px
                                height 34px
                                border-radius: radius
                                border 1px solid #e1e1e1
                                top -1px
                        .cart-txt
                            padding-right 3px
                            +below(1024px)
                                display none
                .content-cart
                    position absolute
                    display none
                    z-index 999
                    padding 15px 15px
                    top 52px
                    background: white
                    border 1px solid cinza
                    border-radius: radius
                    &:before
                        content: ''
                        position: absolute
                        display: block
                        width: 0
                        height: 0
                        border-left: .7rem solid transparent
                        border-right: .7rem solid transparent
                        border-bottom .9rem solid white
                        top: -10px
                .cart
                    .content-cart.visible
                        display flex
                    .content-cart
                        min-height 243px
                        width 322px
                        right 0
                        &:before
                            right: 105px
                            +below(1024px)
                                right 12px
                        .info-cart
                            color colorp
                            font-size 18px
                            padding 0 30px

    // MENU GLOBAL
    .navbar
        .navbar-i
            width 100%
            margin 0
            padding 0
            a
                text-decoration: none
            ul, li
                list-style none
            .dropmenu.mobile
                display none
                .dropmenu-item-w
                    position relative
            .dropmenu>a
                display flex
                align-items: center
                justify-content center
                min-height 32px
                background colorp
                font-weight bold
                color white
                width 100%
                padding 6px 12px
                span.caret
                    margin-left 5px
            .dropmenu
                .dropmenu-item-w
                    .dropmenu-item
                        padding 0
                        .dropmenu-child
                            a
                                color colorp
                            .dropdown-toggle,
                            .dropmenu-child a
                                min-height 36px
                                display flex
                                align-items center
                                justify-content center
                    .dropmenu-first>.dropmenu-child>a:hover
                        background colorp + 90%
                        color colorp
                    .dropmenu-second>.dropmenu-child>a:hover
                        background colorp + 90%
                        color colorp
                    .dropmenu-first>.dropmenu-child
                        border-bottom: 1px dotted colorp
                        background white
                    .dropmenu-first
                        .dropmenu-child
                            border-width 0 1px 1px 1px
                            border-style solid solid dotted solid
                            border-color colorp
                            background white
                            justify-content: space-between
                            .link-first
                                flex 1
                            .link-open-second
                                width 40px
                            &:first-child
                                border-top-width 1px
                                border-top-left-radius radius
                                border-top-right-radius radius
                            &:last-child
                                border-bottom-left-radius radius
                                border-bottom-right-radius radius
                                border-bottom-style solid
                                border-bottom-width 1px
    // MENU DESKTOP
    .navbar
        margin-top 25px
        .navbar-i
            .dropmenu.desktop
                position relative
                lost-column 1/6
                .dropmenu-item-w
                    display none
                    position absolute
                    margin-top 1px
                    width 100%
                    .dropmenu-item
                        position relative
                        z-index 9999
                        margin-top: 0
                        width 100%
                    .dropmenu-first
                        .dropmenu-child
                            display flex
                            position relative
                            text-align left
                            a
                                display flex
                                align-items: center
                                justify-content flex-start
                                font-weight normal
                                font-size 13px
                                white-space normal!important
                                text-decoration none
                                cursor pointer
                                padding 5px 10px
                                span
                                    margin-left 4px
                        .dropmenu-second
                            display none
                            position absolute
                            top -1px
                            a
                                width 100%
                                justify-content flex-start

    // MENU MOBILE
    .navbar
        +below(1024px)
            display none
            height: calc(100vh - 6.15rem)
            overflow-y: scroll
            background: cinza
            .navbar-i
                padding-top 20px
                .dropmenu.desktop
                    display none
                .dropmenu.mobile
                    display flex
                    align-items: center
                    justify-content center
                    flex-direction: column
                    position relative
                    width 100%
                    margin-bottom 8px
                    padding 6px 10px
                    cursor pointer
                    a
                        margin-bottom 1px
                    .dropmenu-item-w
                        display none
                        width 100%
                        margin-top -1px
                        margin-bottom 3px
                        border 1px solid colorp
                        border-radius radius
                        overflow hidden
                        .dropmenu-first
                            border-top 1px solid colorp
                            .dropmenu-child
                                background-color colorp + 90%
                                border none
                                .dropmenu-link-w
                                    min-height 41px
                                    display flex
                                    .link-first
                                        display flex
                                        align-items: center
                                        flex 1
                                        padding 0 15px
                                    .dropdown-toggle
                                        background colorp + 90%
                                .dropmenu-second
                                    display none
                                    border-top 1px dotted colorp
                                    .dropmenu-child
                                        background white
                                        a
                                            justify-content flex-start
                                            padding 0 15px

                    .social-links
                        margin-top 20px
                        .social-link
                            display inline-flex
                            align-items: center
                            justify-content center
                            width 42px
                            height 42px
                            margin 0 5px
                            i.fab
                                color colorp
                                font-size 28px
